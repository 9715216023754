<template>
  <v-container class="pt- px-0">
    <nav class="order__nav">
      <h3 class="cart__title">MY ORDER</h3>
      <ul class="order__menu pa-0">
        <li :class="{ 'active-link': $route.name === 'Cart' }">
          <router-link
            class="order__link order__link-basket"
            :to="{ name: 'Cart' }"
          >
            Basket
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.name === 'Registration' }">
          <router-link
            class="order__link order__link-registration"
            :to="{ name: 'Registration' }"
          >
            Registration
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.name === 'Order complete' }">
          <router-link
            class="order__link order__link-accessories"
            :to="{ name: 'Order complete' }"
          >
            Order complete
          </router-link>
        </li>
      </ul>
    </nav>
    <transition name="slide-fade" mode="out-in">
      <router-view :key="$route.name" />
    </transition>
  </v-container>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.order__nav .order__menu .order__link {
  white-space: nowrap;
  color: #a5a4a4;
}
.active-link {
  .order__link {
    font-weight: 600;
    color: #423f3d !important;

    &::before {
      color: #a8c5a5;
      background-color: #e4f1e3;
    }
  }
}
.order__link-basket::before,
.order__link-registration::before,
.order__link-accessories::before {
  background-color: #eeeeee;
  color: #a5a4a4;
}
</style>
